import * as React from "react";

import { graphql, Link } from "gatsby";
import { IndexBlogQuery } from "../../@types/graphql-types";

export const query = graphql`
  query {
    allMdx(sort: { fields: fields___slug, order: ASC }) {
      nodes {
        frontmatter {
          title
        }
        fields {
          slug
        }
      }
    }
  }
`;

interface IIndexBlogProps {
  data: IndexBlogQuery;
}
export default ({ data }: IIndexBlogProps) => (
  <>
    <h1 className="uppercase font-bold text-3xl text-blue-700">My Shop</h1>
    <h2 className="text-xl font-medium mt-2">Blogs</h2>
    <ul className="ml-5">
      {data.allMdx.nodes.map((blog, idx) => (
        <li key={idx}>
          <Link
            className="hover:underline"
            to={`/blog/${blog.fields.slug}`}
            children={blog.frontmatter.title}
          />
        </li>
      ))}
    </ul>
  </>
);
